import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_REPO, GET_REPO_SUCCESS, GET_REPO_ERROR,
  DEPLOY_REPO, DEPLOY_REPO_SUCCESS, DEPLOY_REPO_ERROR,
  GET_SITELIST, GET_SITELIST_SUCCESS, GET_SITELIST_ERROR,
  GET_CONFIG, GET_CONFIG_SUCCESS,GET_CONFIG_ERROR,
  SET_CONFIG, SET_CONFIG_SUCCESS,SET_CONFIG_ERROR,
  GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR,
   DELETE_SITE_SUCCESS, DELETE_SITE_ERROR, DELETE_SITE

} from './constant';

import { getReposApi, DeployRepoApi, getSitesListApi, getConfigApi, setConfigApi, getAccountApi, deleteSiteApi } from './api';

function* getRepos(action) {
  try {
    const data = yield call(getReposApi, action.param);
    yield put({ type: GET_REPO_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_REPO_ERROR, error });
  }
}

function* DeployRepo(action) {
  try {
    const data = yield call(DeployRepoApi, action.param);
    yield put({ type: DEPLOY_REPO_SUCCESS, data });
  } catch (error) {
    yield put({ type: DEPLOY_REPO_ERROR, error });
  }
}

function* getSitesList(action) {
  try {
    console.log('keys, ',action.param);
    const data = yield call(getSitesListApi, action.param);
    console.log('Data==',data);
    yield put({ type: GET_SITELIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SITELIST_ERROR, error });
  }
}
function* getConfig(action) {
  try {
    const data = yield call(getConfigApi, action.param);
    yield put({ type: GET_CONFIG_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_CONFIG_ERROR, error });
  }
}
function* setConfig(action) {
  try {
    const data = yield call(setConfigApi, action.param);
    yield put({ type: SET_CONFIG_SUCCESS, data });
  } catch (error) {
    yield put({ type: SET_CONFIG_ERROR, error });
  }
}
function* getAccount(action) {
  try {
    const data = yield call(getAccountApi, action.param);
    yield put({ type: GET_ACCOUNT_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_ACCOUNT_ERROR, error });
  }
}
function* deleteSite(action) {
  try {
    const data = yield call(deleteSiteApi, action.param);
    yield put({ type: DELETE_SITE_SUCCESS, data });
  } catch (error) {
    yield put({ type: DELETE_SITE_ERROR, error });
  }
}
/**
 * Get Repo Saga
 */
export function* getRepoSaga() {
  yield takeLatest(GET_REPO, getRepos);
  yield takeLatest(DEPLOY_REPO, DeployRepo);
  yield takeLatest(GET_SITELIST, getSitesList);
  yield takeLatest(GET_CONFIG, getConfig);
  yield takeLatest(SET_CONFIG, setConfig);
  yield takeLatest(GET_ACCOUNT, getAccount);
  yield takeLatest(DELETE_SITE, deleteSite);
}
