import axios from 'axios';
import { API_URL } from '../../../utils/constants';
import { toast } from "react-toastify";

export const getReposApi = async (param) => {
  try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}list-github-repos`,
        headers: { 'Content-Type': 'application/json' },
        data: param
      });
      return response && response.data;
  } catch (e) {
    return [];
  }
};

export const DeployRepoApi = async (searchParam) => {
  try {
    if(searchParam && searchParam.github_url) {
      let response =[];
      if(searchParam && searchParam.loopdeployment) {
        for(let i=0;i<searchParam.loopdeployment;i++) {
          response = await axios({
            method: 'POST',
            url: (searchParam.search.value.account_type==='aws')?`${API_URL}deploy-on-s3`:(searchParam.search.value.account_type==='azure')?`${API_URL}deploy-on-azure`:`${API_URL}deploy-on-gcp`,
            headers: { 'Content-Type': 'application/json' },
            data: searchParam 
          });
          if(response[i] && response[i].code!=200) {
            toast.error(response[i].message,{ autoClose: 5000 });
          }
        }
      }
      toast.success("Repository deployed successfully.",{ autoClose: 5000 });
      return response && response.data
    } else {
      toast.error("Github details required!",{ autoClose: 5000 });
      return [];
    }

  } catch (e) {
    return [];
  }
};
export const getSitesListApi = async (param) => {
  try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}get-sites`,
        headers: { 'Content-Type': 'application/json' },
        data: param
      });
      return response;
  } catch (e) {
    return [];
  }
};
export const getConfigApi = async (param) => {
  try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}list-github-repos`,
        headers: { 'Content-Type': 'application/json' },
        data: param
      });
      return response && response.data;
  } catch (e) {
    return [];
  }
};
export const setConfigApi = async (param) => {
  try {
      const response = await axios({
        method: 'PUT',
        url: `${API_URL}update-account`,
        headers: { 'Content-Type': 'application/json' },
        data: param
      });
      return response && response.data;
  } catch (e) {
    return [];
  }
};

export const getAccountApi = async (param) => {
  try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}get-account`,
        headers: { 'Content-Type': 'application/json' },
        data: param
      });
      return response && response.data;
  } catch (e) {
    return [];
  }
};

export const deleteSiteApi = async (param) => {
  try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}delete-site`,
        headers: { 'Content-Type': 'application/json' },
        data: param
      });
      if(response && response.code ===200){
        toast.success(response.message);
      }
      return response && response.data;
  } catch (e) {
    return [];
  }
};