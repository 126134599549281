const INIT = 'INIT';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const UNAUTH_USER = 'UNAUTH_USER';
const UNAUTH_USER_SUCCESS = 'UNAUTH_USER_SUCCESS';
const UNAUTH_USER_ERROR = 'UNAUTH_USER_ERROR';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_IMG_URL = process.env.REACT_APP_IMG_URL;
const REACT_APP_DEFAULT_CC_USERS = process.env.REACT_APP_DEFAULT_CC_USERS;
const REACT_APP_DEFAULT_ORACLE_CC_USERS = process.env.REACT_APP_DEFAULT_ORACLE_CC_USERS;
const REACT_APP_DEFAULT_TO_USERS = process.env.REACT_APP_DEFAULT_TO_USERS;
const DEFAULT_TO_USERS = (REACT_APP_DEFAULT_TO_USERS)?REACT_APP_DEFAULT_TO_USERS.split(','):'';
const CC_USERS =(REACT_APP_DEFAULT_CC_USERS)?REACT_APP_DEFAULT_CC_USERS.split(','):'';
const ORACLE_CC_USERS = (REACT_APP_DEFAULT_ORACLE_CC_USERS)?REACT_APP_DEFAULT_ORACLE_CC_USERS.split(','):'';
const ORACLE_APP_TYPE = 4;
let FILE_EXTN = ["png","jpg","jpeg","txt","doc","docx","ppt","sql","csv","xlsx","xls",
"bmp","gif","json","msg","output","log","pdf", "pptx", "zip","7z","yaml","yml" ];
const FILE_EXTN_MSG = 'png,jpg,jpeg,txt,doc,docx,ppt,sql,csv,xls,xlsx,bmp,gif,msg,json,output,log,pdf,zip,7z,yaml,yml';
export {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
  API_URL,
  UNAUTH_USER,
  REACT_APP_IMG_URL,
  UNAUTH_USER_SUCCESS,
  UNAUTH_USER_ERROR,
  CC_USERS,
  ORACLE_CC_USERS,
  ORACLE_APP_TYPE,
  FILE_EXTN,
  FILE_EXTN_MSG,
  DEFAULT_TO_USERS
};
