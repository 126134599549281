import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import RequireAuth from './utils/requireAuth';
import SideMenu from './component/SiderBar'
import Heading from './component/Heading'
import Footer from './component/Footer';
import error from './images/404-errorr.png';

const bucketPage = lazy(() => import('./containers/Bucket/bucketDetail'));
const Login = lazy(() => import('./containers/Login'));

const NoMatchPage = () => {
  return (
    <img src={error} alt='404 ERROR' />
  );
};
const Routes = ({ user }, props) => (
  <Router>
    <div className="warper">
      <div className="main-content">
        {user && user.api_token !== undefined && (
          <div className="content">
            <Heading />
            {/* <SideMenu user={user} /> */}
          </div>
        )
        }
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
          <Route exact path="/" component={Login} />
            <Route exact path="/home" component={bucketPage} />
            <Route component={NoMatchPage} />
          </Switch>
        </Suspense>

      </div>
    </div>
    <Footer />
  </Router>
);

const mapStateToProps = ({ LoginStore }) => LoginStore;
export default connect(mapStateToProps)(Routes);
