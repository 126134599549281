import {
  INIT, SUCCESS, LOADING, ERROR,
} from '../../../utils/constants';
import {
  GET_REPO, GET_REPO_SUCCESS, GET_REPO_ERROR,
  DEPLOY_REPO, DEPLOY_REPO_SUCCESS, DEPLOY_REPO_ERROR,
  GET_SITELIST, GET_SITELIST_SUCCESS, GET_SITELIST_ERROR,
  GET_CONFIG, GET_CONFIG_SUCCESS, GET_CONFIG_ERROR,
  SET_CONFIG, SET_CONFIG_SUCCESS, SET_CONFIG_ERROR,
  GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR,
  DELETE_SITE_SUCCESS, DELETE_SITE_ERROR, DELETE_SITE

} from './constant';


const initialState = {
  phase: INIT,
  sitePhase: INIT,
  configPhase: INIT,
  repoInfo: null,
  deployInfo: null,
  siteList: null,
  configInfo: null,
  accountInfo: null,
  deleteSite:null
};

/**
 * User Reducer
 * @param {Object} state
 * @param {Object} action
 */
export function RepoStore(state = initialState, action) {
  switch (action.type) {
    case GET_REPO:
      return {
        ...state,
        phase: LOADING,
      };
    case GET_REPO_SUCCESS:
      //console.log(action.data);
      return {
        ...state,
        phase: SUCCESS,
        repoInfo: action.data,
        error: null,
      };
    case GET_REPO_ERROR:
      return { ...state, phase: ERROR, error: action.error };
    case DEPLOY_REPO:
      return {
        ...state,
        phase: LOADING,
      };
    case DEPLOY_REPO_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        deployInfo: action.data,
        error: null,
      };
    case DEPLOY_REPO_ERROR:
      return { ...state, phase: ERROR, error: action.error };

    case GET_SITELIST:
      return {
        ...state,
        sitePhase: LOADING,
      };
    case GET_SITELIST_SUCCESS:
      return {
        ...state,
        sitePhase: SUCCESS,
        siteList: action.data,
        error: null,
      };
    case GET_SITELIST_ERROR:
      return { ...state, sitePhase: ERROR, error: action.error };

    case GET_CONFIG:
      return {
        ...state,
        configPhase: LOADING,
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        configPhase: SUCCESS,
        configInfo: action.data,
        error: null,
      };
    case GET_CONFIG_ERROR:
      return { ...state, configPhase: ERROR, error: action.error };


    case SET_CONFIG:
      return {
        ...state,
        configPhase: LOADING,
      };
    case SET_CONFIG_SUCCESS:
      return {
        ...state,
        configPhase: SUCCESS,
        configInfo: action.data,
        error: null,
      };
    case SET_CONFIG_ERROR:
      return { ...state, configPhase: ERROR, error: action.error };

    case GET_ACCOUNT:
      return {
        ...state,
        phase: LOADING,
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        phase: SUCCESS,
        accountInfo: action.data,
        error: null,
      };
    case GET_ACCOUNT_ERROR:
      return { ...state, phase: ERROR, error: action.error };
      case DELETE_SITE:
        return {
          ...state,
          sitePhase: LOADING,
        };
      case DELETE_SITE_SUCCESS:
        return {
          ...state,
          sitePhase: SUCCESS,
          deleteSite: action.data,
          error: null,
        };
      case DELETE_SITE_ERROR:
        return { ...state, sitePhase: ERROR, error: action.error };
    default:
      return state;
  }
}
