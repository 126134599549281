import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';
import NetworkService from './utils/network-service';
import { store, persistor } from './stores/main';
import './css/App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

NetworkService.setupInterceptors(store);
window.process = {
  ...window.process,
};
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
        <ToastContainer autoClose={1000} />
      </PersistGate>
    </Provider>
  );
}

export default App;
