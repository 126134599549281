import axios from 'axios';
import { API_URL } from '../../../utils/constants';


export const userLoginApi = async (postData) => {
  try {
    postData.type =2;
   const response = await axios({
    method: 'POST',
    url: `${API_URL}login`,
    data: postData,
    headers: {'Content-Type': 'application/json', 'Authorization' : null},
  });
  if(response.code === 200) {
    //axios.defaults.headers.common['Authorization'] = `Bearer ${response && response.data && response.data.api_token}`;
    return response && response.data 
  } else {
    return response
  }
   
  } catch (e) {
    return e;
  }
};
