const GET_AUTH_USER = 'GET_AUTH_USER';
const GET_AUTH_USER_SUCCESS = 'GET_AUTH_USER_SUCCESS';
const GET_AUTH_USER_ERROR = 'GET_AUTH_USER_ERROR';

const GET_LOGOUT = 'GET_LOGOUT';

export {
  GET_AUTH_USER, GET_AUTH_USER_SUCCESS, GET_AUTH_USER_ERROR,
  GET_LOGOUT
};
