import { all } from 'redux-saga/effects';
import { getUserLoginSaga } from '../containers/Login/Store/saga';
import { getRepoSaga } from '../containers/Bucket/Store/saga';

export default function* rootSaga() {
  yield all([
    getRepoSaga(),
    getUserLoginSaga()
  ]);
}
