import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import logo from '../../images/hyla-logo.png';

const Sidebar = (props) => (

    <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <div className="header-left">
            <div className="navbar-header">
                {/* <Link className="navbar-brand" to="/non-prod"><img src={logo} width="90" alt="Logo" /></Link> */}
            </div>
        </div>

        <Link className={props.location.pathname === "/my-task" ? "nav-item nav-link active" : "nav-item nav-link"}
                to="/my-task">
                <i className="menu-icon fa fa-laptop"></i> </Link>
        
    </div>

);

Sidebar.propTypes = {
    headingName: PropTypes.string,
};

export default withRouter(Sidebar);
