/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import axios from 'axios';
import { UNAUTH_USER } from './constants';

export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use((response) => {
      //console.log('response==',response);
      return response.data
    }, ( error) => {
      //console.log('error==',error);
      if (error.response.status === 401) {
        store.dispatch({ type: UNAUTH_USER });
      }
      return Promise.reject(error.response.data);
    });
  },
};