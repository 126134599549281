import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { LoginStore } from '../containers/Login/Store/reducer';
import { RepoStore } from '../containers/Bucket/Store/reducer';
const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  LoginStore: persistReducer(persistConfig, LoginStore),
  RepoStore
});

export default rootReducer;
