
const GET_REPO = 'GET_REPO';
const GET_REPO_SUCCESS = 'GET_REPO_SUCCESS';
const GET_REPO_ERROR = 'GET_REPO_ERROR';

const DEPLOY_REPO = 'DEPLOY_REPO';
const DEPLOY_REPO_SUCCESS = 'DEPLOY_REPO_SUCCESS';
const DEPLOY_REPO_ERROR = 'DEPLOY_REPO_ERROR';

const GET_SITELIST = 'GET_SITELIST';
const GET_SITELIST_SUCCESS = 'GET_SITELIST_SUCCESS';
const GET_SITELIST_ERROR = 'GET_SITELIST_ERROR';
const GET_CONFIG ='GET_CONFIG';
const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
const GET_CONFIG_ERROR ='GET_CONFIG_ERROR';
const SET_CONFIG = 'SET_CONFIG';
const SET_CONFIG_SUCCESS = 'SET_CONFIG_SUCCESS';
const SET_CONFIG_ERROR = 'SET_CONFIG_ERROR';

const GET_ACCOUNT ='GET_ACCOUNT';
const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
const GET_ACCOUNT_ERROR ='GET_ACCOUNT_ERROR';

const DELETE_SITE = 'DELETE_SITE';
const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
const DELETE_SITE_ERROR = 'DELETE_SITE_ERROR';

export {
  GET_REPO, GET_REPO_SUCCESS, GET_REPO_ERROR,
  DEPLOY_REPO, DEPLOY_REPO_SUCCESS, DEPLOY_REPO_ERROR,
  GET_SITELIST, GET_SITELIST_SUCCESS, GET_SITELIST_ERROR,
  GET_CONFIG, GET_CONFIG_SUCCESS,GET_CONFIG_ERROR,
  SET_CONFIG, SET_CONFIG_SUCCESS,SET_CONFIG_ERROR,
  GET_ACCOUNT, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERROR,
  DELETE_SITE, DELETE_SITE_ERROR, DELETE_SITE_SUCCESS
};
