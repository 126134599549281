import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getLogout} from '../../containers/Login/Store'
import { Link, withRouter } from 'react-router-dom';
import adminAvtr from '../../images/admin.png';
import axios from 'axios';
import { API_URL } from '../../utils/constants';

class Header extends Component {

  logout = async () => {
    const { getLogout, history, LoginStore: { user } } = this.props;
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.api_token}`;
    // await axios({
    //   method: 'POST',
    //   url: `${API_URL}logout`,
    //   data:{},
    //   headers: { 'Content-Type': 'application/json' },
    // });
    axios.defaults.headers.common['Authorization'] = null;
    localStorage.clear();
    await getLogout();
    
    history.push('/');  
  }

  render(){
    return(
      <header id="header" className="header">
       <div className="user-area dropdown float-right">
            <Link to="#" className="dropdown-toggle active" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="user-avatar rounded-circle" src={adminAvtr} alt="User Avatar" />
            </Link>

            <div className="user-menu dropdown-menu">
            {/* <Link className="nav-link" to="/user-settings" ><i className="fa fa-user"></i>Manage Profile</Link> */}
            <Link className="nav-link" to="#" onClick={() => this.logout()}><i className="fa fa-power-off"></i>Logout</Link>
            </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = RepoStore => RepoStore;

const mapDispatchToProps = {
     getLogout: () => getLogout(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter( Header));
