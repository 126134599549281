import React from 'react';
import { PropTypes } from 'prop-types';

const Footer = () => (
    <div className="footer">
        
    </div>
);

Footer.propTypes = {
    headingName: PropTypes.string,
};

export default Footer;
